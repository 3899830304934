var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AddOrEditCategoryChild" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            "z-index": 100,
            "is-mask": false,
            title: (_vm.mode === "add" ? "新增" : "编辑") + "薪资项",
            placement: "right",
            size: "large",
            visible: _vm.childVisible,
          },
          on: {
            close: function ($event) {
              _vm.childVisible = false
            },
            onSubmit: _vm.submitPreserve,
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c("SalaryItem", {
                ref: "salaryItem",
                attrs: {
                  form: _vm.form,
                  mode: _vm.mode,
                  "type-no": _vm.typeNo,
                },
                on: { getSalaryData: _vm.getSalaryData },
              }),
              _vm.typeNo !== "A06"
                ? _c("BaseMessage", {
                    attrs: { form: _vm.form, "type-no": _vm.typeNo },
                  })
                : _vm._e(),
              _vm.typeNo !== "A06"
                ? _c("PayrollRules", {
                    attrs: { form: _vm.form, "type-no": _vm.typeNo },
                    on: {
                      getSalaryData: _vm.getSalaryData,
                      setUpCustomFormula: _vm.setUpCustomFormula,
                    },
                  })
                : _vm._e(),
              _c("OtherRules", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !["A01", "A06"].includes(_vm.typeNo) &&
                      _vm.form.calcRuleType !== "3",
                    expression:
                      "!['A01','A06'].includes(typeNo)&&form.calcRuleType !== '3'",
                  },
                ],
                ref: "otherRules",
                attrs: { "type-no": _vm.typeNo, form: _vm.form },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("FormulaSetting", {
        ref: "FormulaSetting",
        attrs: { form: _vm.form },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }